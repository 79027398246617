import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import { css } from '@emotion/css';
import { Button, useStyles2 } from '@grafana/ui';

interface Props extends PanelProps<SimpleOptions> {}


const getStyles = () => {
  return {
    wrapper: css`
      font-family: Open Sans;
      position: relative;
    `,
    input: css`
      position: relative;
      width: 80%;

    `,
    button: css`
      position: relative;
      padding: 15px;
      margin-left: 2%;
    `,
    li: css`
      display: flex;
      margin-bottom: 5px;
    ` ,
    deleteButton: css`
    display: inline-block;
    padding: 8px 16px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  `,
 tableCell: css`
     display: table-cell;
     padding: 16px; 
     font-size: 0.875rem;
     text-align: left; 
     font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
     font-weight: 400; 
     line-height: 1.43; 
     letter-spacing: 0.01071em; 
     vertical-align: inherit; 
    `
  };
};
interface Device{
  name: string;
  address: Number
  highlighted: boolean,
  coef: number
}
export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
 // const theme = useTheme2();

  const [Devices,setDevices] = useState<Device[]>([]);




const createQuery = (
  from: string,
  to: string,
  datasourceUID: string | null = null,
  rawSql: string
) => {
  return {
    from: from,
    to: to,
    queries: [
      {
        refId: 'A',
        datasource: {
          uid: datasourceUID
        },
        //datasourceId: datasourceId,
        rawSql: rawSql,
        format: 'table',
      },
    ],
  };
};

const fetchData = (
  apiUrl: string,
  method: string,
  headers: Record<string, string>,
  body?: any
): Promise<any> => {
  return fetch(apiUrl, {
    method: method,
    headers: headers,
    credentials: 'include',
    body: body ? JSON.stringify(body) : undefined,
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      console.error('There was a problem with the fetch operation:', error);
      return null;
    });
};
const grafanaApiBaseUrl = options.api; // Replace with your Grafana instance URL  http://localhost:3000/api/ds/query
const method = 'POST';
const datasourceUID = data.request?.targets[0].datasource?.uid; // Replace with the data source ID you discovered earlier

//table select for vemat_devices 
const sqlCommands = [`SELECT address,name,coef FROM devices order by address asc`,
`SELECT * FROM report_settings order by id asc`

];

const select_vemat_devices = createQuery('now-1h', 'now', datasourceUID, sqlCommands[0]);

//const headers = {'Content-Type': 'application/json'};
// Initialize 'headers' using useMemo
const headers = useMemo(() => {
// Replace the following with your actual header initialization logic
return {
  //Authorization: 'Bearer your_access_token',
  'Content-Type': 'application/json',
};
}, []);
const reloadTable = useCallback(() => {
  fetchData(grafanaApiBaseUrl, method, headers, select_vemat_devices).then(data => {
    if (data) {
      const ids = data.results.A.frames[0].data.values[0];
      const names = data.results.A.frames[0].data.values[1];
      const coefs = data.results.A.frames[0].data.values[2];
      
      const mappedData = ids.map((id: number, index: number) => ({
        address:id,
        name: names[index],
        coef: coefs[index],
        highlighted: false,
       
      }));
      //map error array

      setDevices(mappedData);
      //napamovani nactenych dat do StateVariable
    }
  });

 
}, [grafanaApiBaseUrl, method, headers, select_vemat_devices, setDevices ]);
//data fetching and processing
useEffect(() => {
  reloadTable();
  return () => {
    // Cleanup code here
  };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

function deviceHighlighted(id: number){
//podle toho zda je marked (oznacene)
//asi to mas v nejakem stavu or smthing.
// teď je to v interface, ale udelej si to nejak podle sebe,.
let _devices = [...Devices];
let _device = _devices[id];

if(!_device.highlighted){
  //zarizeni bylo oznaceno (zvyrazneno)
  const event = new  CustomEvent('panel1ToPanel2Event', { detail: { key: 'addDevice',dev: _device} });
  document.dispatchEvent(event);
}else{
  const event = new  CustomEvent('panel1ToPanel2Event', { detail: { key: 'removeDevice',dev: _device} });
  document.dispatchEvent(event);
}

console.log(_device);
_devices[id].highlighted = ! _devices[id].highlighted;
setDevices(_devices);
//console.log(...Devices);
//console.log(_device);



//obrať stav, podle stavu posli event do druheho panelu
//device.highlighted = !device.highlighted;
//TODO: 
//uloz si context

}




  const styles = useStyles2(getStyles);
  return (
    <>
      {Devices.map((row, index) => (
          <div key={index}>
 


              <Button
               onClick={()=>deviceHighlighted(index)}
               className={row.highlighted?styles.button : styles.deleteButton}
                value={row.name}>
                  
              {row.name}
              </Button>
          
          
               
            

          </div>
          
        ))}
</>
  );
};
